<template>
  <tr>
    <td>
      {{ item.productName }}
      <span v-if="item.variantName">- {{ item.variantName }}</span>
      <template v-if="sku"><br><small>SKU: {{ sku }}</small></template>
    </td>
    <td class="text-center">{{ quantity }}</td>
    <td class="text-right">{{ $money(unitPrice) }}</td>
    <td class="text-right">{{ $money(subTotal) }}</td>
    <td class="text-right">{{ $money(totalTaxes) }}</td>
    <td class="text-right">{{ $money(total) }}</td>
    <td>{{ getOrderItemStatusText(status) }}</td>
  </tr>
</template>

<script>
import { get } from 'lodash-es'
import formatsCurrency from '@/mixins/formatsCurrency'
import orderStatus from '@/mixins/orderStatus'

export default {
  mixins: [formatsCurrency, orderStatus],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    sku () {
      return get(this.item, 'variant.sku')
    },
    quantity () {
      return get(this.item, 'quantity', 1)
    },
    unitPrice () {
      return get(this.item, 'unitPrice', 0)
    },
    subTotal () {
      return Number(this.quantity) * Number(this.unitPrice)
    },
    totalTaxes () {
      const taxes = get(this.item, 'taxes', {})
      let total = 0

      for (const tax in taxes) {
        total += Number(taxes[tax])
      }

      return total
    },
    total () {
      return this.subTotal + this.totalTaxes
    },
    status () {
      return get(this.item, 'status')
    }
  }
}
</script>
